import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { PageContext } from '~/shared/providers/PageContext';
import useViewportName from '~/shared/hooks/use-viewport-name';
import {
  SIDEBAR_WIDTH,
  VARIANTS,
  SIDEBAR_TOP_PADDING,
} from '~/shared/components/SideBarNavigation/constants';
import {
  isCollapsibleSideBar,
  isVisibleSideBar,
} from '~/shared/components/SideBarNavigation/SideBarNavigationService';
import SideBarNavigation from '~/shared/components/SideBarNavigation';

const baseStyles = () => css`
  margin: 0 auto;
  display: flex;
`;

/**
 * Prevents content overflow and centers the content on wide viewports.
 */
const Wrapper = styled('div')(baseStyles);

const contentStyles = () => css`
  margin: 0 auto;
  width: 100%;
  overflow-x: hidden;
`;

const withSideBarContentStyles = ({ theme, showSideBar }) =>
  showSideBar &&
  css`
    width: ${`calc(100% - ${SIDEBAR_WIDTH})`};
    max-width: 554px;
    ${theme.mq.giga} {
      max-width: 1064px;
    }
  `;

const withCollapsibleSideBarStyles = ({ theme, showCollapsibleSideBar }) =>
  showCollapsibleSideBar &&
  css`
    max-width: 468px;
    ${theme.mq.kiloToMega} {
      max-width: 568px;
    }
  `;

const Content = styled('div')(
  contentStyles,
  withSideBarContentStyles,
  withCollapsibleSideBarStyles,
);

const sideBarNavigationStyles = ({ theme }) => css`
  position: sticky;
  align-self: flex-start;
  width: ${SIDEBAR_WIDTH};
  top: calc(${theme.spacings.nav.desktop} + ${SIDEBAR_TOP_PADDING}px);
  left: calc((100vw - ${theme.maxWidth}) / 2);

  ${theme.mq.megaToGiga} {
    padding-left: ${theme.spacings.kilo};
  }

  ${theme.mq.tera} {
    margin-left: -${theme.spacings.mega};
  }
`;

const StyledSideBarNavigation = styled(SideBarNavigation)(
  sideBarNavigationStyles,
);

function PageWrapper(props) {
  const { sideBarNavigation } = useContext(PageContext);
  const viewportName = useViewportName();

  const showSideBar = isVisibleSideBar(viewportName, sideBarNavigation);
  const showCollapsibleSideBar = isCollapsibleSideBar(
    viewportName,
    sideBarNavigation,
  );

  return (
    <Wrapper data-selector="page-wrapper">
      {showSideBar && (
        <StyledSideBarNavigation
          {...sideBarNavigation}
          variant={VARIANTS.PRIMARY}
        />
      )}
      <Content
        {...props}
        showSideBar={showSideBar}
        showCollapsibleSideBar={showCollapsibleSideBar}
      />
    </Wrapper>
  );
}

/**
 * @component
 */
export default PageWrapper;
