import React, { useEffect, useContext } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import isEmpty from 'lodash/fp/isEmpty';
import Tagger from '@elbwalker/tagger';

import api from '~/shared/services/api';
import { RequestError } from '~/shared/util/errors';
import * as Url from '~/shared/services/url';
import { pagePropsPropTypes } from '~/shared/util/shared-prop-types';
import useEditButton from '~/shared/hooks/use-edit-button';
import usePageMetrics from '~/shared/hooks/use-page-metrics';
import Footer from '~/shared/components/Footer';
import Navigation from '~/shared/components/Navigation';
import Meta from '~/shared/components/Meta';
import TermsAndConditions from '~/shared/components/TermsAndConditions';
import PageWrapper from '~/shared/components/PageWrapper';
import LazyHydrate from '~/shared/components/LazyHydrate';
import { StoreContext, ActionTypes } from '~/shared/providers/StoreContext';
import { getVercelPasswordProtectionCookie } from '~/shared/services/AppService';
import { formatUrl } from '~/shared/services/website-backend/url-formatter';
import { hasLocaleOnPath } from '~/shared/services/LinkService';
import {
  DEFAULT_LOCALE_KEY,
  DEFAULT_LOCALE_ONE_DOMAIN,
} from '~/shared/constants/locales';
import { VERCEL_CDN_CACHE_PARAMS } from '~/shared/constants';
import useOptimizelyData from '~/shared/services/optimizely/use-optimizely-data';
import {
  NAVIGATION_REVAMP_FEATURE_TOGGLE,
  WB_REPLACEMENT_SITE_FEATURE_TOGGLE,
  SHOP_INTEGRATION_FEATURE_TOGGLE,
} from '~/shared/services/optimizely/constants';
import HeaderNavigation from '~/shared/components/HeaderNavigation';
import { PartnerNavigation } from '~/shared/components/PartnerNavigation';
import { NAVIGATION_TYPES } from '~/shared/components/PartnerNavigation/constants';
import { CONTENTFUL_PAGE_TYPES } from '~/shared/constants/pages';
import { withLegacyAppGetInitialProps } from '~/shared/services/legacy-app-get-initial-props';
import NinetailedWrapper from '~/shared/components/NinetailedWrapper';
import useWalkerGlobal from '~/shared/hooks/use-walker-global';

const CallbackWidget = dynamic(
  () =>
    import(
      // eslint-disable-next-line max-len
      /* webpackChunkName: 'component-CallbackWidget' */ '~/domains/sales-leads/components/CallbackWidget'
    ),
  {
    ssr: false,
  },
);
const EditButton = dynamic(
  () =>
    import(
      // eslint-disable-next-line max-len
      /* webpackChunkName: 'component-EditButton' */ '~/shared/components/editor/EditButton'
    ),
  {
    ssr: false,
  },
);

const Popup = dynamic(
  () =>
    import(
      // eslint-disable-next-line max-len
      /* webpackChunkName: 'component-Popup' */ '~/domains/sales-leads/components/Popup'
    ),
  {
    ssr: false,
  },
);

const layoutsMap = {
  defaultLayout: dynamic(
    () =>
      import(
        /* webpackChunkName: 'sections-list-default' */ '~/shared/components/SectionsList'
      ),
  ),
  [CONTENTFUL_PAGE_TYPES.PRESS_ARTICLE]: dynamic(
    () =>
      import(
        /* webpackChunkName: 'page-press-article' */ '~/domains/press-article/layouts/PressArticle'
      ),
  ),
  [CONTENTFUL_PAGE_TYPES.CAREERS_POSITION]: dynamic(
    () =>
      import(
        /* webpackChunkName: 'page-careers-position' */ '~/domains/careers/components/Position'
      ),
  ),
  caseStudy: dynamic(
    () =>
      import(
        /* webpackChunkName: 'page-case-study' */ '~/domains/case-study/layouts/CaseStudy'
      ),
  ),
  [CONTENTFUL_PAGE_TYPES.SBG_INDEX_PAGE]: dynamic(
    () =>
      import(
        /* webpackChunkName: 'page-sbg-index' */ '~/domains/business-guide/layouts/SbgIndexPage'
      ),
  ),
  [CONTENTFUL_PAGE_TYPES.SBG_CATEGORY_PAGE]: dynamic(
    () =>
      import(
        // eslint-disable-next-line max-len
        /* webpackChunkName: 'page-sbg-category-page' */ '~/domains/business-guide/layouts/SbgCategoryPage'
      ),
  ),
  [CONTENTFUL_PAGE_TYPES.SBG_POST]: dynamic(
    () =>
      import(
        /* webpackChunkName: 'page-sbg-post' */ '~/domains/business-guide/layouts/SbgPost'
      ),
  ),
  quotationToolPage: dynamic(
    () =>
      import(
        /* webpackChunkName: 'page-quotation-tool' */ '~/domains/quotation-tool/layouts/QuotationTool'
      ),
  ),
};

function getLayout(contentType, isQuotationTool) {
  if (isQuotationTool) {
    return layoutsMap.quotationToolPage;
  }
  return layoutsMap[contentType] || layoutsMap.defaultLayout;
}

const Page = ({
  site,
  request,
  page = {},
  products: allProducts = {},
  termsOverride,
  fees,
  footnoteConfig,
  ninetailedPreviewData,
  theme,
}) => {
  const router = useRouter();
  const loadEditButton = useEditButton();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // eslint-disable-next-line no-unused-vars
  const [_, dispatchToStore] = useContext(StoreContext);
  const { featureToggles = {} } = useOptimizelyData();

  const {
    footer = {},
    callbackWidget,
    popup,
    taggingAction,
    taggingEntity,
    taggingTrigger,

    ntExperiencesVariantsMap = {},
    route,
    localizedUrls,
  } = page;

  const isQuotationTool =
    request.pathname.includes(
      process.env.NEXT_PUBLIC_QUOTATION_PAGE_URL_CHECK,
    ) && process.env.NEXT_PUBLIC_QUOTATION_PAGE_ENABLED === 'true';

  const { products } = allProducts;
  const { contentType } = request;
  const PageLayout = getLayout(contentType, isQuotationTool);

  const tagger = React.useMemo(() => {
    if (!taggingAction || !taggingEntity || !taggingTrigger) {
      return {};
    }

    const t = Tagger();

    return {
      ...t.entity(taggingEntity),
      ...t.action(taggingTrigger, taggingAction),
    };
  }, [taggingAction, taggingEntity, taggingTrigger]);

  useEffect(() => {
    dispatchToStore({
      type: ActionTypes.SET_SESSION_QUERY,
      payload: { ...router.query },
    });
  }, [router.asPath, router.query, dispatchToStore]);

  usePageMetrics();

  const walkerGlobal = useWalkerGlobal(page, request);

  // NOTE: we hydrate footer on idle when WB replacement is enabled
  // because we're requesting SEO pages dynamically and cannot wait
  // until they will be visible in viewport in order to not hurt SEO
  const footerHydrationProps = featureToggles[
    WB_REPLACEMENT_SITE_FEATURE_TOGGLE
  ]
    ? { whenIdle: true }
    : { whenVisible: true };

  return (
    <NinetailedWrapper
      request={request}
      ntExperiencesVariantsMap={ntExperiencesVariantsMap}
      ninetailedPreviewData={ninetailedPreviewData}
    >
      <Meta
        request={request}
        site={site}
        page={page}
        route={route}
        localizedUrls={localizedUrls}
        products={products}
        fees={fees}
        theme={theme}
      />

      {renderNavigation(page, featureToggles)}

      <PageWrapper
        data-selector="page-wrap"
        role="main"
        data-elbglobals={walkerGlobal}
        {...tagger}
      >
        {loadEditButton && (
          <LazyHydrate ssrOnly>
            <EditButton id={request.id} fixed>
              Edit page
            </EditButton>
          </LazyHydrate>
        )}

        <PageLayout {...page} />

        <LazyHydrate whenIdle>
          <TermsAndConditions
            products={products}
            termsOverride={termsOverride}
            footnoteConfig={footnoteConfig}
          />
        </LazyHydrate>

        {!isEmpty(callbackWidget) && callbackWidget.available && (
          <LazyHydrate whenIdle>
            <CallbackWidget widget={callbackWidget} />
          </LazyHydrate>
        )}

        {!isEmpty(popup) && (
          <LazyHydrate whenIdle>
            <Popup {...popup} />
          </LazyHydrate>
        )}
      </PageWrapper>

      <LazyHydrate {...footerHydrationProps}>
        <Footer {...footer} localizedUrls={localizedUrls} />
      </LazyHydrate>
    </NinetailedWrapper>
  );
};

Page.propTypes = pagePropsPropTypes;

Page.getInitialProps = (ctx, baseUrl, request) => {
  const pageGetInitialProps = () => {
    const { query = {} } = ctx;

    let pathname;
    try {
      const parsedUrl = Url.parse(ctx.asPath, request.origin);
      pathname = parsedUrl.pathname;
    } catch (err) {
      // URL interface throws TypeError "Invalid URL" exception
      // when invalid website paths are requested (e.g. "//%2561dmin/")
      // thus we catch parser exceptions and treat invalid URLs as 404s
      // https://developer.mozilla.org/en-US/docs/Web/API/URL/URL
      return Promise.reject(new RequestError({ status: 404 }));
    }

    const urlQuery = Url.cleanApiParams(query);

    // send country code as a query param to api
    // it is needed for referrals redirects
    // https://github.com/sumup/website-backend/blob/master/src/presentation/http/endpoints/pages.ts#L15
    const countryCode = request.query?.[VERCEL_CDN_CACHE_PARAMS.GEO_COUNTRY];
    if (countryCode) {
      urlQuery.country = countryCode;
    }

    // for support center redirects
    urlQuery.hostname = baseUrl.host;
    const finalLocale =
      ctx.locale === DEFAULT_LOCALE_KEY
        ? DEFAULT_LOCALE_ONE_DOMAIN
        : ctx.locale;

    // if global route, sets locale, like in careers
    const finalPathname = hasLocaleOnPath(pathname)
      ? pathname
      : `/${finalLocale}${pathname}`;

    const url = formatUrl(
      `/api/page${finalPathname}`,
      baseUrl.protocol,
      baseUrl.host,
      urlQuery,
    );
    const vercelPasswordProtectionCookie =
      getVercelPasswordProtectionCookie(ctx);

    return api
      .getEntry(url, { vercelPasswordProtectionCookie })
      .then((entry) => {
        const PageLayout = getLayout(entry.contentType);

        return PageLayout.render.preload().then(() => ({ ...entry }));
      });
  };

  return withLegacyAppGetInitialProps(
    ctx,
    baseUrl,
    request,
    pageGetInitialProps,
  );
};

export default Page;

function renderNavigation(page, featureToggles) {
  const {
    navigation = {},
    infoBanner = {},
    extraLevelNavigation = {},
    sideBarNavigation = {},
    extraLevelNavigationIcon,
    extraLevelNavigationGroupLabel,
    secondaryLogo = {},
    headerNavigation,
    localizedUrls,
    navigation_type: navigationType,
  } = page;

  const hasNewNavigation = !!featureToggles[NAVIGATION_REVAMP_FEATURE_TOGGLE];
  const shopIntegration = !!featureToggles[SHOP_INTEGRATION_FEATURE_TOGGLE];

  if (navigationType === NAVIGATION_TYPES.PARTNER_PAGE) {
    return (
      <PartnerNavigation
        id={navigation.id}
        name={navigation.name}
        contentType={navigation.contentType}
        partner={navigation.partner}
        logoLink={navigation.logoLink}
      />
    );
  }

  return hasNewNavigation && headerNavigation ? (
    <HeaderNavigation
      {...headerNavigation}
      infoBanner={infoBanner}
      secondaryLogo={secondaryLogo}
      localizedUrls={localizedUrls}
      sideBarNavigation={sideBarNavigation}
      shopIntegration={shopIntegration}
    />
  ) : (
    <Navigation
      {...navigation}
      infoBanner={infoBanner}
      secondaryLogo={secondaryLogo}
      localizedUrls={localizedUrls}
      sideBarNavigation={sideBarNavigation}
      extraLevelNavigation={extraLevelNavigation}
      extraLevelNavigationIcon={extraLevelNavigationIcon}
      extraLevelNavigationGroupLabel={extraLevelNavigationGroupLabel}
    />
  );
}
